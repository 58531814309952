@use 'sidebar';
@use 'header';

@import '../theme/variables';

.content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
}

.content {
    position: relative;
    min-height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.main-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    max-width: 100%;
    overflow-x: auto;
    gap: 24px;
    margin-bottom: 53px;
    background: $color-background-paper;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 100%;
    }

    .page-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        padding: 0px;
        width: 100%;
        height: 64px;
        gap: 24px;

        .page-title-content {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
        }

        .page-title-header {
            order: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
            gap: 8px;

            > div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 8px;
            }

            .page-title-name{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                line-height: 123.5%;
                letter-spacing: 0.25px;
                color: #161718;
            }
        }

        .page-title-actions {
            order: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;
        }

        .page-title-divider{
            width: 100%;
            box-sizing: border-box;
            border-color: #E2E3E4;
        }

        .page-breadcrumbs {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;

            .breadcrumbs-link {
                padding: 0px;
                gap: 4px;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: 0.15px;
                color: $color-text-secondary;
            }
        }
    }
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    border-top: 1px solid $color-other-divider;
    padding: 16px 24px;

    .footer-typography {
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.15px;

        .poweredByLogo {
            height: 16px;
            margin-left: 6px;
        }
    }
}

.Mui-selected {
    * {
        color: $color-primary-main;
    }
}

.grid-toolbar {
    border: 1px solid sidebar.$color-neutral-100;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.grid-data {
    border: 1px solid sidebar.$color-neutral-100;
    border-radius: 0 0 4px 4px !important;
}