
// Do not edit directly
// Generated on Tue, 04 Apr 2023 15:38:32 GMT

$color-brand-indigo-50: #e7e9f8;
$color-brand-indigo-100: #bfc4ee;
$color-brand-indigo-200: #979fe3;
$color-brand-indigo-300: #6f7bd8;
$color-brand-indigo-400: #4656cd;
$color-brand-indigo-500: #303fb1;
$color-brand-indigo-600: #253088;
$color-brand-indigo-700: #1a2260;
$color-brand-indigo-800: #0f1438;
$color-brand-indigo-900: #040610;
$color-brand-blue-50: #fafcff;
$color-brand-blue-100: #c7e1ff;
$color-brand-blue-200: #94c6ff;
$color-brand-blue-300: #61abff;
$color-brand-blue-400: #2e90ff;
$color-brand-blue-500: #0076fb;
$color-brand-blue-600: #005ec7;
$color-brand-blue-700: #004694;
$color-brand-blue-800: #002e61;
$color-brand-blue-900: #00162e;
$color-brand-turquoise-50: #f6fdfd;
$color-brand-turquoise-100: #cbf6f6;
$color-brand-turquoise-200: #a0eeee;
$color-brand-turquoise-300: #75e6e6;
$color-brand-turquoise-400: #4adede;
$color-brand-turquoise-500: #26cfcf;
$color-brand-turquoise-600: #1ea4a4;
$color-brand-turquoise-700: #167979;
$color-brand-turquoise-800: #0e4e4e;
$color-brand-turquoise-900: #062323;
$color-brand-purple-50: #f9f0ff;
$color-brand-purple-100: #e4bdff;
$color-brand-purple-200: #cf8aff;
$color-brand-purple-300: #ba57ff;
$color-brand-purple-400: #a522ff;
$color-brand-purple-500: #8e00f0;
$color-brand-purple-600: #7000bd;
$color-brand-purple-700: #52008a;
$color-brand-purple-800: #330057;
$color-brand-purple-900: #150024;
$color-common-slate: #29344c;
$color-common-white: #ffffff;
$color-common-black: #000000;
$color-neutral-50: #fcfcfd;
$color-neutral-100: #e2e3e4;
$color-neutral-200: #c7c9cc;
$color-neutral-300: #acb0b4;
$color-neutral-400: #91969c;
$color-neutral-500: #777d84;
$color-neutral-600: #5e6368;
$color-neutral-700: #464a4e;
$color-neutral-800: #2e3033;
$color-neutral-900: #161718;
$color-green-50: #f5fffb;
$color-green-100: #c4fde7;
$color-green-200: #92fcd3;
$color-green-300: #61fabf;
$color-green-400: #2ff8ab;
$color-green-500: #08ed95;
$color-green-600: #06bc76;
$color-green-700: #048a57;
$color-green-800: #035938;
$color-green-900: #012819;
$color-sky-blue-50: #d9f0fc;
$color-sky-blue-100: #aadef8;
$color-sky-blue-200: #7bccf4;
$color-sky-blue-300: #4cbaf0;
$color-sky-blue-400: #1ca7ec;
$color-sky-blue-500: #118ac6;
$color-sky-blue-600: #0d6997;
$color-sky-blue-700: #094868;
$color-sky-blue-800: #052738;
$color-sky-blue-900: #010709;
$color-yellow-50: #fffdfa;
$color-yellow-100: #feecc8;
$color-yellow-200: #fdda94;
$color-yellow-300: #fcc964;
$color-yellow-400: #fbb832;
$color-yellow-500: #f5a505;
$color-yellow-600: #c38304;
$color-yellow-700: #916203;
$color-yellow-800: #5f4002;
$color-yellow-900: #2d1e01;
$color-red-50: #fef7f6;
$color-red-100: #facdc7;
$color-red-200: #f6a398;
$color-red-300: #f27968;
$color-red-400: #ee503a;
$color-red-500: #e12d13;
$color-red-600: #b2230f;
$color-red-700: #831a0b;
$color-red-800: #551107;
$color-red-900: #260703;
$color-illustrations-sand: #fdda94;
$color-illustrations-strong-yellow: #f1a921;
$color-illustrations-orange: #f69c5b;
$color-illustrations-peach: #f27968;
$color-illustrations-pale-peach: #f5ccb8;
$color-illustrations-sky-blue: #1ca7ec;
$color-illustrations-green: #06bc76;
$color-gradients-gradient-01: #000000;
$color-gradients-gradient-02: #000000;
$color-gradients-gradient-03: #000000;
$color-gradients-gradient-04: #000000;
$color-primary-light: #c7e1ff;
$color-primary-main: #0076fb;
$color-primary-dark: #004694;
$color-primary-contrast: #ffffff;
$color-primary-states-outlined-hover-background: rgba(#0076fb, .08);
$color-primary-states-outlined-resting-border: rgba(#0076fb, .5);
$color-primary-states-contained-hover-background: #005ec7;
$color-secondary-light: #cf8aff;
$color-secondary-main: #a522ff;
$color-secondary-dark: #7000bd;
$color-secondary-contrast: #ffffff;
$color-secondary-states-outlined-hover-background: rgba(#a522ff, .1);
$color-secondary-states-outlined-resting-border: rgba(#a522ff, .5);
$color-secondary-states-contained-hover-background: #7000bd;
$color-tertiary-light: #cbf6f6;
$color-tertiary-main: #4adede;
$color-tertiary-dark: #1ea4a4;
$color-tertiary-contrast: #ffffff;
$color-tertiary-states-outlined-hover-background: rgba(#4adede, .1);
$color-tertiary-states-outlined-resting-border: rgba(#4adede, .5);
$color-tertiary-states-contained-hover-background: #1ea4a4;
$color-success-light: #c4fde7;
$color-success-main: #06bc76;
$color-success-dark: #035938;
$color-success-contrast: #ffffff;
$color-success-states-outlined-hover-background: rgba(#06bc76, .1);
$color-success-states-outlined-resting-border: rgba(#06bc76, .5);
$color-success-states-contained-hover-background: #048a57;
$color-success-alert-content: #035938;
$color-success-alert-background: #f5fffb;
$color-info-light: #aadef8;
$color-info-main: #1ca7ec;
$color-info-dark: #0d6997;
$color-info-contrast: #ffffff;
$color-info-states-outlined-hover-background: rgba(#1ca7ec, .1);
$color-info-states-outlined-resting-border: rgba(#1ca7ec, .5);
$color-info-states-contained-hover-background: #118ac6;
$color-info-alert-content: #094868;
$color-info-alert-background: #d9f0fc;
$color-warning-light: #fffdfa;
$color-warning-main: #fbb832;
$color-warning-dark: #c38304;
$color-warning-contrast: #ffffff;
$color-warning-states-outlined-hover-background: rgba(#fbb832, .1);
$color-warning-states-outlined-resting-border: rgba(#fbb832, .5);
$color-warning-states-contained-hover-background: #f5a505;
$color-warning-alert-content: #916203;
$color-warning-alert-background: #fffdfa;
$color-error-light: #facdc7;
$color-error-main: #f27968;
$color-error-dark: #e12d13;
$color-error-contrast: #ffffff;
$color-error-states-outlined-hover-background: rgba(#f27968, .1);
$color-error-states-outlined-resting-border: rgba(#f27968, .5);
$color-error-states-contained-hover-background: #e12d13;
$color-error-alert-content: #831a0b;
$color-error-alert-background: #fef7f6;
$color-text-primary: #161718;
$color-text-secondary: #464a4e;
$color-text-disabled: #acb0b4;
$color-text-highlight: #0076fb;
$color-text-primary-inverse: #fcfcfd;
$color-text-secondary-inverse: #e2e3e4;
$color-background-default: #fcfcfd;
$color-background-paper: #ffffff;
$color-background-dark: #29344c;
$color-action-active: rgba(#161718, .54);
$color-action-hover: rgba(#161718, .04);
$color-action-selected: rgba(#161718, .08);
$color-action-disabled: rgba(#161718, .26);
$color-action-disabled-background: rgba(#161718, .12);
$color-action-focused: rgba(#161718, .12);
$color-other-divider: #e2e3e4;
$color-other-outlined-border: #c7c9cc;
$color-other-backdrop-overlay: rgba(#161718, .5);
$color-other-filled-input-background: rgba(#161718, .09);
$color-other-standard-input-line: rgba(#161718, .4);
$color-other-snackbar: #5e6368;
$color-other-rating-active: #fcc964;