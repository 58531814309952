.creative-preview-box {
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: fit-content;
}

.creative-preview-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  color: white;
}

.creative-preview-box:hover .creative-preview-overlay {
  display: flex;
  cursor: pointer;
}