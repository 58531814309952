@import "../theme/variables";

.sidebar-drawer {
    overflow-x: hidden;
    gap: 16px;
    background-color: $color-common-white;
    box-shadow: inset -1px 0px 0px $color-other-divider !important;
    border-right: 0px !important;

    .top-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 16px !important;
        gap: 16px;
        height: 66px;

        .logo-container {
            padding: 16px;
            gap: 10px;
            height: 56px;
            border-radius: 4px;

            img {
              width: 105.62px;
              height: 24px;
              cursor: pointer;
            }
        }

        .avatar-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px 16px 0px 0px;
            min-width: 40px;
            height: 40px;

            .avatar {
                border-radius: 64px;
                padding: 0px;
                align-items: center;
            }
        }

        .top-content-name {
            font-style: normal;
            font-size: 16px !important;
            line-height: 150%  !important;
            letter-spacing: 0.15px;
            color: $color-text-primary;
        }

        .top-content-description {
            font-style: normal;
            font-size: 14px !important;
            line-height: 143%  !important;
            letter-spacing: 0.15px;
            color: $color-text-secondary;
        }
    }

    .main-nav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 8px 16px !important;
    }

    .sidebar-link {
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 48px;
        border-radius: 4px;

        .sidebar-link-icon {
            padding: 0px 16px 0px 0px;
            width: 24px;
            height: 24px;
            min-width: 0px;
        }

        .sidebar-link-text {
            padding: 4px 0px !important;
            width: 136px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.15px;
            color: $color-text-primary;
            margin: 0;
        }
    }
}