@import "../theme/variables";

.header {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 100%;
    height: 56px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    .header-content{

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        box-shadow: inset 0px -1px 0px #E2E3E4;
        width: 100%;
        height: 56px;

        background: #FFFFFF;

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }

    .header-actions {

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;

        width: 64px;
        height: 24px;

        flex: none;
        order: 0;
        flex-grow: 0;

        .icon-notifications {
            padding: 0px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .icon-user-menu {
            padding: 0px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .MuiIconButton-root {
            height: 24px;
            width: 24px;

            .MuiTouchRipple-root {
                width: 100%;
                height: 100%;
            }
            :nth-child(2) {
                margin-left: 16px;
            }
        }
    }

    .header-icon {
        color: $color-neutral-700;
    }
}
